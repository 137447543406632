import { merge } from "theme-ui";
import originalTheme from "@lekoarts/gatsby-theme-jodie/src/gatsby-plugin-theme-ui/index";

const theme = merge(originalTheme, {
  fonts: {
    body: `"Cutive Mono", monospace`,
    heading: `"Libre Baskerville", serif`,
  },
  colors:{
    primary: `#141414`,
    primaryLight: `#777777`,
    text: `#141414`,
    heading: `#141414`,
  },
  styles:{
    a: {
      textDecoration: "underline"
    },
    h1: {
      variant: `text.heading`,
      fontSize: [4, 4, 4, 4, 5],
      mt: 2,
    },
    h2: {
      variant: `text.heading`,
      fontSize: [3, 3, 3, 3, 4],
      mt: 2,
    },
    h3: {
      variant: `text.body`,
      fontSize: [1, 1, 1, 1, 1],
      fontFamily: `"Cutive Mono", monospace`,
      marginBottom: [3, 3, 3, 3, 3],
      mt: 3,
    },
    h4: {
      variant: `text.body`,
      fontSize: [1, 1, 1, 1, 1],
      marginBottom: [2, 2, 2, 2, 2],
      fontFamily: `"Cutive Mono", monospace`,
    },
    h5: {
      variant: `text.heading`,
      fontSize: [1, 1, 1, 1, 1],
    },
    h6: {
      variant: `text.heading`,
      fontSize: 1,
      mb: 2,
    },
    ul:{
      marginBottom: "2rem"
    },
    li: {
      marginTop: "0.8rem",
      fontSize: "0.9rem"
    }
  },
  content: {
    imageList: {
      ".gatsby-image-wrapper:not(:last-child)": {
        marginBottom: [3, 3, 4, 4],
      },
    },
  },
});

export default theme;
